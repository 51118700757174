<template>
  <collective-detail
    breadcrumb-route="projects"
    item-type="projects"
    item-type-single="project"
    has-likes
  />
</template>

<script>
import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';

export default {
  name: 'ProjectDetails',
  components: {
    CollectiveDetail,
  },
};
</script>
